import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import LandingPageTemplate from '../../components/LandingPageTemplate';
import mainImage from '../../images/automatiktuer_faq_03.webp';
import {Link} from 'gatsby';

const context = {
  title: 'FAQ Automatiktüren', // Große Haupt-Überschrift
  subTitle: 'Automatischen Schiebetüren: Preise, Wartungsintervall, Inspektion, Normen und mehr', // Kleine Seiten-Überschrift über der Haupt-Überschrift
  description: 'Häufig gestellte Fragen zu Automatiktüren',
  image: mainImage, //Haupt-Bild der Landingpage
  imageAlt: "FAQ Automatiktüren", //Alternativtext / Beschreibung zum Haupt-Bild
  contactButtonText: "Neue Frage stellen",
  configurator: false, // optional, aktiviert den Konfirgurator Button wenn 'true'
  configuratorButtonText: "Schiebetür online konfigurieren", // optional, überschreibt den default Titel
  suggestedProductsTitle: "Passende Automatiktür24 Produkte", // optional, überschreibt den default Titel
  suggestedProducts: [
    '/produkte/automatische-schiebetuer/',
    '/produkte/automatische-teleskopschiebetuer/',
    '/produkte/break-out-automatikschiebetuer/'
  ],
  text:
  <div>
    <Accordion
      allowZeroExpanded>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Muss ich meine automatische Schiebetür regelmäßig überprüfen lassen?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Ja. Auf Basis der deutschen bzw. europäischen Industrienormen (DIN 18650, EN 16005) sowie der
            technischen Regeln für Arbeitsstätten (ASR 1.7) ist eine wiederkehrende
            sicherheitstechnische Überprüfung von Automatiktüren durch einen Sachkundigen mindestens einmal
            jährlich erforderlich. Eine zweimal jährliche Wartung wird empfohlen.
            <br/>
            <br/>
            ASR 1.7: Technische Regeln für die Arbeitsstätte für Türen und Tore<br/>
            AutSchR: Richtlinie für automatische Schiebetüren in Rettungswegen<br/>
            EltVTR: Richtlinie für elektrische Verriegelungssysteme von Türen in Rettungswegen<br/>
            DIN EN 16005: Kraftbetätigte Türen, Nutzungssicherheit, Anforderungen und Prüfverfahren<br/>
            DIN 18650: Automatische Türsysteme
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Wie oft muss ich eine Wartung oder UVV Prüfung (Unfallverhütungsvorschriften) an meiner Automatikschiebetür durchführen lassen?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Um einen technisch einwandfreien Betrieb sicherzustellen ist eine einmal jährliche Wartung durchzuführen.
            Für automatische Türanlagen in Flucht- und Rettungswegen wird zur Sicherstellung der Notfunktion,
            auf Basis der Herstellervorgaben und der Norm DIN 18650 sowie EN 16005 eine zweimal jährliche Wartung empfohlen.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Welchen zusätzlichen Vorteil bringt eine jährliche Wartung?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Eine regelmäßige Wartung sichert nachhaltig die Funktion Ihrer Automatikschiebetür,
            lässt frühzeitig Verschleiß erkennen und verringert somit das Ausfallrisiko.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Gibt es Bestandsschutz für meine alte Türanlage?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Nein. Betreiber von automatischen Schiebetüren sind verpflichtet Ihre Türanlagen auf dem aktuellen
            Stand der Technik und Normen zu halten. Im Rahmen der regelmäßigen Wartungen werden unsere geschulten
            Servicetechniker auf die neusten Normen hinweisen und Empfehlungen zur Modernisierung aussprechen.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Was kostet eine neue Automatiktür?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Die Kosten einer neuen Automatiktür sind abhängig von Faktoren wie Anzahl der Türflügel, Durchgangsbreite,
            Durchgangshöhe, Fluchtwegzulassung, Teleskopausführung, Break-Out Funktion, einer <Link to="/produkte/einbruchhemmende-automatikschiebetuer/">
            Einbruchhemmung</Link> oder der Auswahl einer Sonderfarbe bzw. einer Sonderverglasung.
            Der Preis einer automatischen Schiebetür beginnt ab 4.000 Euro.
            Die Preisspanne einer <Link to="/produkte/karusselldrehtuer/">Karusselltür</Link> beginnt ab 18.000 Euro,
            die eines automatischen <Link to="/produkte/automatische-drehtuer/">Drehtürantrieb</Link> ab 3.000 Euro.
            <br/>
            <br/>
            Gerne kontaktieren Sie unsere Fachleute und erhalten
            eine <Link to="/kontakt/">persönliche Beratung</Link> oder nutzen Sie
            unseren <Link to="/automatiktuer-konfigurator/">Online Automatiktür-Konfigurator</Link>.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Was kostet die Modernisierung einer Automatiktür im Bestand?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Die Modernisierung einer Automatiktür im Bestand kann unter Verwendung der alten Antriebsmechanik oder
            der alten Schiebeflügel umgesetzt werden. Diese Faktoren ergeben regelmäßig eine Preisspanne
            zwischen 2.500 – 5.000 Euro. Eine Modernisierung ist eine beliebte und nachhaltige Lösung den Produktlebenszyklus
            Ihrer automatischen Schiebetür zu verlängern und mit geringem Aufwand bestehende Bauteile zu erhalten.
            <br/>
            <br/>
            Gerne kontaktieren Sie unsere Fachleute und erhalten
            eine <Link to="/kontakt/">persönliche Beratung</Link> oder nutzen Sie
            unseren <Link to="/automatiktuer-konfigurator/">Online Automatiktür-Konfigurator</Link>.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Welche Vorteil habe ich durch den Einsatz einer Automatikschiebetür?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Eine <Link to="/produkte/automatische-schiebetuer/">automatische Schiebetür</Link> ermöglicht einen barrierefreien Eingang und verbesserter Energieeffizienz
            Ihres Gebäudes. Erhöhter Komfort und <Link to="/themen/automatiktueren-sicherheit-corona-covid-19/">
            Hygieneschutz</Link> gehen mit der Automatisierung einher.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Welche Hersteller für automatische Türsysteme gibt es?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Auf unserer Automatiktür-Themenseite finden Sie eine <Link to="/themen/automatiktueren-hersteller/">Liste
            aller Hersteller im Bereich Türautomation</Link>.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Ist meine Automatikschiebetür noch betriebssicher und entspricht diese Stand der Technik?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Es können Gefahren durch Scheren, Quetschen, Anstoßen oder Einziehen entstehen.
            Gerne bewerten wir Ihre automatische Schiebetür und deren Einbausituation.
            Hierzu erstellen wir Ihnen eine unverbindliche <Link to="/themen/automatiktueren-sicherheit-risiko/">
            Online Automatiktür-Risikoanalyse</Link>.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Ich habe eine alte manuelle Eingangstür, kann ich diese auf- oder umrüsten?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Durch die Einsatzmöglichkeit unterschiedlicher Türsysteme kann für jede Einbausituation eine Lösung
            gefunden werden.
            <br/>
            <br/>
            Gerne kontaktieren Sie unsere Fachleute und erhalten
            eine <Link to="/kontakt/">persönliche Beratung</Link> oder nutzen Sie
            unseren <Link to="/automatiktuer-konfigurator/">Online Automatiktür-Konfigurator</Link>.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

    </Accordion>
  </div>
}

const Page = () => {

  return(
    <LandingPageTemplate pageContext={context} />
  )
}

export default Page;
